const state = {
    namespaced: true,
    state: {
        title: process.env.VUE_APP_PUBLIC_TITLE,
        designedBy: process.env.VUE_APP_DESIGNED_BY,
        version: process.env.VUE_APP_VERSION,
        client: process.env.VUE_APP_CLIENT_NAME,
        isLoading: true,
        isDrawerOpen: true,
        generalDialogStatus: false,
        generalDialogTitle: "",
        generalDialogMessage: "",
        questionDialogStatus: false,
        questionDialogTitle: "",
        questionDialogMessage: "",
        questionDialogOnConform: () => {},
        questionDialogOnCancel: () => {},
        mediaDialogFile: "",
        mediaDialogStatus: false,
    },
    actions: {
        downloadFile(context, {url, fileName}) {
            const link = document.createElement('a');
            link.href = url
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        },
        doneLoading(ctx, params ) {
            ctx.commit('DONE_LOADING');
        },
        setState ({ commit }, params) {
            commit('SET_STATE', params)
        },
    },
    mutations: {
        DONE_LOADING(state) {
            state.isLoading = false;
        },
        SET_STATE (state, params) {
            state[params.key] = params.value
        },
    }
}

export default state
